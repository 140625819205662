// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import WelcomePage from './components/WelcomePage';
import MessagesPage from './components/MessagesPage';
import AdminLogin from './pages/AdminLogin';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './components/NotFound'; // Ensure this import is correct

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-300">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/villages/:villageId/messages" element={<MessagesPage />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            {/* Wildcard route for 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {/* ToastContainer should be placed once in your app */}
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
