// src/locales/sk.js

const strings = {
  navbar: {
    brand: 'Rozhlas',
    adminLogin: 'Prihlásenie Admina',
    toggleDarkMode: 'Prepínať Tmavý Režim',
  },
  welcomePage: {
    title: 'Vitajte v Dedinskom Rozhlase',
    description: 'Vyberte svoju dedinu pre zobrazenie najnovších správ.',
    metaTitle: 'Dedinský Rozhlas - Oznámenia z vašej obce',
    metaDescription: 'Dedinský Rozhlas vám prináša najnovšie informácie a oznámenia priamo z vašej obce. Zostaňte informovaní o všetkom dôležitom.',
    metaKeywords: 'Dedinský rozhlas, obecné oznámenia, správy, komunitné správy',
    chooseVillage: '-- Vyberte dedinu --',
    viewMessages: 'Zobraziť Správy',
    howItWorks: 'Ako to funguje',
    step1: 'Vyberte svoju obec z rozbaľovacieho zoznamu.',
    step2: 'Kliknite na "Zobraziť Správy".',
    step3: 'Prezrite si najnovšie oznámenia a aktuality.',
    quote: 'Dedinský Rozhlas? Turbo verzia vašej babky!',
    quoteAuthor: 'Ján, obyvateľ obce Hrnčiarovce',
  },
  adminLogin: {
    title: 'Prihlásenie Admina',
    emailLabel: 'Emailová Adresa',
    passwordLabel: 'Heslo',
    signIn: 'Prihlásiť sa',
    signInAlt: 'Ikona Prihlásenia',
    invalidCredentials: 'Neplatný email alebo heslo.',
    loginSuccess: 'Úspešne ste sa prihlásili!',
  },
  dashboard: {
      title: 'Dashboard',
      logout: 'Odhlásiť sa',
      manageMessages: 'Pridať Oznam',
      fillAllFields: 'Prosím, vyplňte všetky polia.',
      messageTitle: 'Názov Správy',
      titlePlaceholder: 'Názov Správy',
      messageBody: 'Obsah Správy',
      bodyPlaceholder: 'Obsah Správy',
      send: 'Odoslať',
      sending: 'Odosielanie...',
      errorSendMessage: 'Chyba pri odosielaní správy.',
      confirmSend: 'Ste si istý, že chcete odoslať správu?',
      confirmYes: 'Áno, odoslať',
      confirmNo: 'Nie, zrušiť',
    },
  messagesPage: {
    messagesFor: 'Správy pre',
    totalMessages: 'Celkový počet správ:',
    noMessages: 'Žiadne správy dostupné pre túto dedinu.',
    endOfMessages: 'Hurá! Videli ste všetky správy.',
    loading: 'Načítavanie...',
    yays: 'Hurá! Videli ste všetky správy.',
    errorLoadingMessages: 'Nepodarilo sa načítať správy. Skúste to znova neskôr.',
  },
  notFound: {
    title: '404',
    message: 'Stránka nenájdená',
    goHome: 'Späť na Domovskú Stránku',
  },
  toast: {
    errorLoadingVillages: 'Nepodarilo sa načítať dediny. Skúste to znova neskôr.',
    errorFetchingVillages: 'Chyba pri načítaní dedín',
    errorLoadingMessages: 'Nepodarilo sa načítať správy. Skúste to znova neskôr.',
    errorNotLoggedIn: 'Musíte byť prihlásený, aby ste pristúpili k tejto stránke.',
    errorNoPermission: 'Nemáte oprávnenie prístupovať k tejto stránke.',
    successLogin: 'Úspešne ste sa prihlásili!',
    errorLogin: 'Neplatný email alebo heslo.',
    successLogout: 'Úspešne ste sa odhlásili.',
    errorLogout: 'Chyba pri odhlasovaní. Skúste to znova.',
    successSendMessage: 'Správa bola úspešne odoslaná!',
    errorSendMessage: 'Nepodarilo sa odoslať správu. Skúste to znova.',
  },
};

export default strings;