// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import './index.css';
import App from './App';

// Get the root element from the HTML
const container = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(container);

// Render the App component
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
