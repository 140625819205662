import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { motion } from 'framer-motion'; // For animations
import strings from '../locales/sk'; // Import translated strings

const MessagesPage = () => {
  const { villageId } = useParams();
  const location = useLocation();
  const { villageName } = location.state || { villageName: 'Vybraná dedina' };
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Number of messages per page
  const [totalMessages, setTotalMessages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState('');

  const fetchMessages = useCallback(async () => {
    try {
      const response = await apiClient.get(`/villages/${villageId}/messages`, {
        params: { page, limit },
      });
      const newMessages = response.data.messages;

      // Deduplicate messages by ID
      setMessages((prevMessages) => {
        const existingIds = new Set(prevMessages.map((msg) => msg.id));
        const filteredNewMessages = newMessages.filter((msg) => !existingIds.has(msg.id));
        return [...prevMessages, ...filteredNewMessages];
      });
      setTotalMessages(response.data.totalMessages);

      // Set hasMore based on whether all messages have been loaded
      if (page * limit >= response.data.totalMessages) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setPage((prevPage) => prevPage + 1);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError(strings.messagesPage.errorLoadingMessages);
      toast.error(strings.toast.errorLoadingMessages);
      setHasMore(false);
    }
  }, [page, limit, villageId, strings.toast.errorLoadingMessages, strings.messagesPage.errorLoadingMessages]);

  useEffect(() => {
    // Reset state when village changes
    setMessages([]);
    setPage(1);
    setHasMore(true);
    setTotalMessages(0);
    setError('');
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [villageId]);

  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-6">
        {strings.messagesPage.messagesFor} {villageName}
      </h2>
      <p className="text-gray-700 dark:text-gray-300 mb-8">
        {strings.messagesPage.totalMessages} {totalMessages}
      </p>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <InfiniteScroll
        dataLength={messages.length}
        next={fetchMessages}
        hasMore={hasMore}
        loader={
          <div className="flex items-center justify-center my-4">
            <svg
              className="animate-spin h-8 w-8 text-blue-600 dark:text-blue-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          </div>
        }
        endMessage={
          <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
            <b>{strings.messagesPage.endOfMessages}</b>
          </p>
        }
      >
        {/* Updated Timeline List */}
        <ul className="relative border-l-4 border-gray-200 dark:border-gray-700">
          {messages.map((msg, index) => (
            <motion.li
              key={msg.id} // Unique key based on message ID
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="mb-6 ml-6 relative group hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-4 transition-colors"
            >
              {/* Timeline Indicator - Dash */}
              <div className="absolute -left-6 top-1/2 transform -translate-y-1/2 w-6 border-b-2 border-blue-500"></div>

              {/* Flex Container for Time and Content */}
              <div className="flex items-center">
                {/* Time */}
                <div className="mr-4 text-gray-500 dark:text-gray-400 text-sm w-40 flex-shrink-0 whitespace-nowrap">
                  {new Date(msg.created_at).toLocaleDateString('sk-SK', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}{' '}
                  {new Date(msg.created_at).toLocaleTimeString('sk-SK', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
                {/* Message Content */}
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{msg.title}</h3>
                  <p className="mt-1 text-gray-700 dark:text-gray-300">{msg.body}</p>
                </div>
              </div>
            </motion.li>
          ))}
        </ul>
      </InfiniteScroll>
    </div>
  );
};

export default MessagesPage;
