// src/components/WelcomePage.js

import React, { useEffect, useState } from 'react';
import apiClient from '../apiClient';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaBullhorn } from "react-icons/fa";
import strings from '../locales/sk';
import { Helmet } from 'react-helmet';
import Select from 'react-select';

const WelcomePage = () => {
  const [villages, setVillages] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVillages = async () => {
      try {
        const response = await apiClient.get('/villages');
        setVillages(response.data.villages.map(village => ({
          value: village.url_slug,
          label: village.name
        })));
        setLoading(false);
      } catch (err) {
        console.error(strings.toast.errorFetchingVillages, err);
        setError(strings.toast.errorFetchingVillages);
        toast.error(strings.toast.errorFetchingVillages);
        setLoading(false);
      }
    };

    fetchVillages();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setSelectedVillage(selectedOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedVillage) {
      navigate(`/villages/${selectedVillage.value}/messages`, { state: { villageName: selectedVillage.label } });
    }
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>{strings.welcomePage.metaTitle}</title>
        <meta name="description" content={strings.welcomePage.metaDescription} />
        <meta name="keywords" content={strings.welcomePage.metaKeywords} />
      </Helmet>
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center">
          <FaBullhorn className="h-12 w-12 text-blue-600 dark:text-blue-400" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
            {strings.welcomePage.title}
          </h2>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <svg className="animate-spin h-8 w-8 text-blue-600 dark:text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
            </svg>
          </div>
        ) : error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <label htmlFor="village" className="sr-only">
                {strings.welcomePage.chooseVillage}
              </label>
              <Select
                id="village"
                value={selectedVillage}
                onChange={handleSelectChange}
                options={villages}
                placeholder={strings.welcomePage.chooseVillage}
                className="text-gray-900 dark:text-gray-100"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#3b82f6',
                    primary: '#1e40af',
                    neutral0: '#ffffff',
                    neutral80: '#1f2937',
                  },
                })}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? '#e2e8f0' : 'white',
                    color: 'black',
                    borderColor: '#cbd5e1',
                    boxShadow: state.isFocused ? '0 0 0 2px #3b82f6' : 'none',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: 'black',
                  }),
                  option: (base, state) => ({
                    ...base,
                    color: state.isSelected ? 'white' : '#1f2937',
                    backgroundColor: state.isSelected ? '#1e40af' : state.isFocused ? '#e2e8f0' : 'white',
                  }),
                }}
              />
            </div>

            <div>
              <button
                type="submit"
                disabled={!selectedVillage}
                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                  selectedVillage
                    ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400'
                    : 'bg-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-400'
                }`}
              >
                {strings.welcomePage.viewMessages}
              </button>
            </div>
          </form>
        )}

        <div className="mt-12 space-y-4 text-center">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{strings.welcomePage.howItWorks}</h3>
            <ol className="list-decimal list-inside text-gray-600 dark:text-gray-300 mt-2">
              <li>{strings.welcomePage.step1}</li>
              <li>{strings.welcomePage.step2}</li>
              <li>{strings.welcomePage.step3}</li>
            </ol>
          </div>
          <blockquote className="italic text-gray-500 dark:text-gray-400">
            "{strings.welcomePage.quote}" - {strings.welcomePage.quoteAuthor}
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;