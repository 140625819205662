import axios from 'axios';
import { auth } from './firebaseConfig';
import { getIdToken } from 'firebase/auth';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

apiClient.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;

    if (user) {
      const idToken = await getIdToken(user, /* forceRefresh */ true);
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
