import React, { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaSignOutAlt, FaSpinner, FaPaperPlane, FaPlus } from 'react-icons/fa';
import { motion } from 'framer-motion';
import strings from '../locales/sk';
import apiClient from '../apiClient';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Dashboard = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [village, setVillage] = useState({ name: '', urlSlug: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminVillage = async () => {
      try {
        const response = await apiClient.get('/admin/village');
        setVillage({ name: response.data.name, urlSlug: response.data.urlSlug });
        fetchMessages(response.data.urlSlug, 1);
      } catch (err) {
        console.error('Error fetching admin village:', err);
        toast.error(strings.toast.errorLoadingMessages);
      }
    };
    fetchAdminVillage();
  }, []);

  const fetchMessages = useCallback(async (villageSlug, page) => {
    try {
      const response = await apiClient.get(`/villages/${villageSlug}/messages`, {
        params: { page, limit },
      });
      const newMessages = response.data.messages;

      setMessages((prevMessages) => {
        const existingIds = new Set(prevMessages.map((msg) => msg.id));
        const filteredNewMessages = newMessages.filter((msg) => !existingIds.has(msg.id));
        return [...prevMessages, ...filteredNewMessages];
      });
      setHasMore(page * limit < response.data.totalMessages);
      setPage((prevPage) => prevPage + 1);
    } catch (err) {
      console.error('Error fetching messages:', err);
      toast.error(strings.toast.errorLoadingMessages);
      setHasMore(false);
    }
  }, [limit]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        toast.success(strings.toast.successLogout);
        navigate('/admin/login');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
        toast.error(strings.toast.errorLogout);
      });
  };

  const handleSendMessage = () => {
    if (!title || !body) {
      setError(strings.dashboard.fillAllFields);
      toast.error(strings.dashboard.fillAllFields);
      return;
    }
    setShowConfirmAlert(true);
  };

  const confirmSendMessage = async () => {
    setIsSubmitting(true);
    setError('');

    try {
      await apiClient.post('/messages', { title, body });
      toast.success(strings.toast.successSendMessage);
      setTitle('');
      setBody('');
      setMessages([]);
      fetchMessages(village.urlSlug, 1);
      setIsModalOpen(false);
    } catch (err) {
      console.error('Error sending message:', err);
      setError(strings.dashboard.errorSendMessage);
      toast.error(strings.toast.errorSendMessage);
    } finally {
      setIsSubmitting(false);
      setShowConfirmAlert(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-6">
        <motion.h2
          className="text-3xl font-extrabold text-gray-900 dark:text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {village.name}
        </motion.h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <FaPlus className="h-4 w-4 mr-2" /> {strings.dashboard.manageMessages}
          </button>
          <button
            onClick={handleSignOut}
            className="flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <FaSignOutAlt className="h-4 w-4 mr-2" /> {strings.dashboard.logout}
          </button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="w-full max-w-4xl mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
          {strings.dashboard.manageMessages}
        </h3>
        {error && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {error}
          </motion.p>
        )}
        <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              {strings.dashboard.messageTitle}
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder={strings.dashboard.titlePlaceholder}
            />
          </div>

          <div>
            <label htmlFor="body" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              {strings.dashboard.messageBody}
            </label>
            <textarea
              id="body"
              name="body"
              rows="8"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder={strings.dashboard.bodyPlaceholder}
            ></textarea>
          </div>

          {showConfirmAlert && (
            <div className="mb-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 rounded-md">
              <p>{strings.dashboard.confirmSend}</p>
              <div className="mt-2 flex space-x-2">
                <button
                  onClick={confirmSendMessage}
                  className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                >
                  {strings.dashboard.confirmYes}
                </button>
                <button
                  onClick={() => setShowConfirmAlert(false)}
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  {strings.dashboard.confirmNo}
                </button>
              </div>
            </div>
          )}

          <div>
            <button
              type="button"
              onClick={handleSendMessage}
              disabled={!title || !body || isSubmitting}
              className={`w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white transition-colors duration-200 ${
                !isSubmitting
                  ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  : 'bg-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-400'
              }`}
            >
              {isSubmitting ? (
                <>
                  <FaSpinner className="animate-spin h-5 w-5 mr-3" />
                  {strings.dashboard.sending}
                </>
              ) : (
                <>
                  <FaPaperPlane className="h-5 w-5 mr-2" />
                  {strings.dashboard.send}
                </>
              )}
            </button>
          </div>
        </form>
      </Modal>

      <div className="lg:w-2/3 lg:pl-8 mt-12 lg:mt-0">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
          {strings.messagesPage.messagesForAdminVillage}
        </h3>
        <InfiniteScroll
          dataLength={messages.length}
          next={() => fetchMessages(village.urlSlug, page)}
          hasMore={hasMore}
          loader={
            <div className="flex justify-center my-4">
              <FaSpinner className="animate-spin h-8 w-8 text-blue-600 dark:text-blue-400" />
            </div>
          }
          endMessage={
            <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
              <b>{strings.messagesPage.endOfMessages}</b>
            </p>
          }
        >
          <ul className="relative border-l-4 border-gray-200 dark:border-gray-700">
            {messages.map((msg, index) => (
              <motion.li
                key={msg.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="mb-6 ml-6 relative group hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-4 transition-colors"
              >
                <div className="absolute -left-6 top-1/2 transform -translate-y-1/2 w-6 border-b-2 border-blue-500"></div>
                <div className="flex items-center">
                  <div className="mr-4 text-gray-500 dark:text-gray-400 text-sm w-40 flex-shrink-0 whitespace-nowrap">
                    {new Date(msg.created_at).toLocaleDateString('sk-SK', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}{' '}
                    {new Date(msg.created_at).toLocaleTimeString('sk-SK', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{msg.title}</h3>
                    <p className="mt-1 text-gray-700 dark:text-gray-300">{msg.body}</p>
                  </div>
                </div>
              </motion.li>
            ))}
          </ul>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Dashboard;
